import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ITaxon, TCountingMethod } from '../../../taxon/taxon.interface';
import { TaxonService } from '../../../taxon/taxon.service';
import { EditComponent } from 'apps/mba-cpr-survey-portal/src/app/shared/components/edit/edit.component';

@Component({
  selector: 'app-taxon-overview',
  templateUrl: './taxon-overview.component.html',
  styleUrls: ['./taxon-overview.component.scss'],
})
export class TaxonOverviewComponent implements OnChanges {
  @ViewChild('editComponent') editComponent: EditComponent;

  @Input() isTopLevelTaxon!: boolean;
  @Input() taxon: ITaxon;

  readonly formGroup = new FormGroup({
    taxonName: new FormControl<string>(undefined),
    rank: new FormControl<string>(undefined),
    cprTaxonId: new FormControl<number>(undefined),
    aphiaId: new FormControl<number>(undefined),
    itisCode: new FormControl<number>(undefined),
    routineAnalysis: new FormControl<boolean>(undefined),
    isOnlyEverPlussed: new FormControl<boolean>(undefined),
    countingMethod: new FormControl<TCountingMethod>(undefined),
    lifeStage: new FormControl<string>(undefined),
    comments: new FormControl<string>(undefined),
    parentTaxonIds: new FormArray<FormControl<number>>([new FormControl()]),
  });

  constructor(private readonly taxaDirectory: TaxonService) {}

  ngOnChanges() {
    if (this.taxon) {
      this.formGroup.reset();
      this.formGroup.patchValue(this.taxon);
    }
  }

  onSave($event: Partial<ITaxon>) {
    this.taxaDirectory.patchOne('', this.taxon._id, $event);

    this.editComponent.markAsUpdated();
  }
}
